<template>
    <v-container fluid>
        <v-row>
            <v-col 
                style="display: flex; align-content: center; align-items: center;"
                cols="6"
                lg="6"
                md="6"
                sm="6"
            >
                <h3>Ready to Submit - {{ serviceProjectResponse.idFormatted }}</h3>
            </v-col>
            <v-col 
                cols="6"
                style="text-align: right;"
            >
                <v-chip
                    color="var(--color__main)"
                    outlined
                    dark
                >
                    {{ serviceProjectResponse.statusDescription }}
                </v-chip>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12">
                <hr />
            </v-col>
        </v-row>

        <v-row>
            <v-col
                cols="12"
            >                    
                <JobInformation 
                    :id="serviceProjectResponse.idService"
                    :project="projectSelected"
                    :showProjects="false"
                    :showJobSummary="false"
                    :showButtonsCreateEditProject="false"
                    :showButtonsCreateEditVersion="false"
                    :showClassification="true"
                    :showDisabledClassification="false"
                />
            </v-col>
        </v-row>

        <v-row>
            <v-col
                style="padding: 10px;"
                cols="12"
                lg="6"
                md="6"
                sm="12"
                v-for="(item, index) in listPermittingRequest" :key="index"
            >
                <v-card
                    class="mx-auto"
                >
                    <v-card-title class="text-h6 lighten-2" style="border-bottom: solid 1px var(--color__cinza)">
                        <v-row>
                            <v-col 
                                style="display: flex; align-content: center; align-items: center;"
                                cols="12"
                                lg="4"
                                md="4"
                                sm="12"
                            >
                                <v-tooltip top>
                                    <template v-slot:activator="{ on }">
                                        <span v-on="on">{{ item.permitTitle }}</span>
                                    </template>
                                    <span>{{ item.permitDescription }}</span>
                                </v-tooltip>
                            </v-col>
                            <v-col 
                                style="text-align: right;"
                                cols="12"
                                lg="8"
                                md="8"
                                sm="12"
                            >
                                <v-btn-toggle
                                    v-model="item.apply"
                                    small
                                >
                                    <v-btn 
                                        @click="item.apply = 0; item.notes = ''" 
                                        class="btnToogleDenied v-btn-large"
                                    >
                                        <v-icon left class="btnToogleDenied">mdi-thumb-down-outline</v-icon>Not Applicable
                                    </v-btn>

                                    <v-btn 
                                        @click="item.apply = 1" 
                                        class="btnToogleApproved v-btn-large"
                                    >
                                        <v-icon left class="btnToogleApproved">mdi-thumb-up-outline</v-icon>Applicable
                                    </v-btn>

                                </v-btn-toggle>
                            </v-col>
                        </v-row>
                    </v-card-title>
                    
                    <v-card-text style="margin-top: 20px;">
                        <v-row>
                            <v-col 
                                cols="12"
                                lg="6"
                                md="6"
                                sm="12"
                            >
                                <label>Responsible?</label>
                                <v-combobox
                                    v-model="item.responsibleSelected"
                                    :items="listTypeResponsibles"
                                    item-text="responsibleDescription"
                                    item-value="id"
                                    multiple
                                    small-chips
                                    clearable
                                    outlined
                                    dense
                                ></v-combobox>
                            </v-col>
                            <v-col 
                                v-if="item.responsibleSelected != null && item.responsibleSelected != undefined && item.responsibleSelected.filter(res => res.id == 3).length > 0"
                                cols="12"
                                lg="6"
                                md="6"
                                sm="12"
                            >
                                <label>Contractor</label>
                                <v-combobox
                                    v-model="item.contractorSelected"
                                    :items="listContractor"
                                    item-text="name"
                                    item-value="id"
                                    dense
                                    clearable
                                    outlined
                                ></v-combobox>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col 
                                cols="12"
                                lg="12"
                                md="12"
                                sm="12"
                            >

                                <label>Notes</label>
                                <v-textarea
                                    v-model="item.notes"
                                    :disabled="checkDisable(item)"
                                    single-line
                                    rows="3"
                                    row-height="30"
                                    outlined
                                >
                                </v-textarea>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>

        <br />
            
        <div 
            class="text-center"
        >            
            <v-row>
                <v-col 
                    style="margin-left: -20px !important;"
                    cols="7"
                    lg="7"
                    md="7"
                    sm="8"
                >
                    <v-row>
                        <v-col 
                            style="text-align: right"
                            cols="6"
                            lg="9"
                            md="7"
                            sm="6"
                        >
                            <v-tooltip top>
                                <template v-slot:activator="{ on }">
                                    <v-btn 
                                        class="mx-2 v-btn-large"
                                        v-on="on"
                                        @click="cancel"
                                        style="background-color: var(--color__red) !important; color: #ffffff !important;"
                                    >
                                        Cancel
                                    </v-btn>
                                </template>
                                <span>Cancel</span>
                            </v-tooltip>
                        </v-col>
                        <v-col 
                            style="text-align: center"
                            cols="6"
                            lg="3"
                            md="5"
                            sm="6"
                        >
                            <v-tooltip top>
                                <template v-slot:activator="{ on }">
                                    <v-btn 
                                        class="mx-2 v-btn-large"
                                        v-on="on"
                                        style="background-color: var(--color__silver) !important; color: #ffffff !important"
                                        @click="save(false)"
                                    >
                                        Save
                                    </v-btn>
                                </template>
                                <span>Save and continue later</span>
                            </v-tooltip>
                        </v-col>
                    </v-row>
                </v-col>

                <v-col 
                    cols="5"
                    lg="5"
                    md="5"
                    sm="4"
                >
                    <v-row>
                        <v-col 
                            style="text-align: left"
                            cols="12"
                            lg="6"
                            md="6"
                            sm="6"
                        >
                            <v-tooltip top>
                                <template v-slot:activator="{ on }">
                                    <v-btn
                                        class="mx-2"
                                        v-on="on"
                                        @click="moveToNextStep()"
                                    >
                                        Move to Next
                                    </v-btn>
                                </template>
                                <span>Move to Next</span>
                            </v-tooltip>
                        </v-col>
                    </v-row>
                </v-col>

            </v-row>
        </div>

        <ActionDialog 
            v-on:methodConfirmToCall="dialog.methodConfirm"
            :showDialog.sync="dialog.show"
            :headerTitle="dialog.headerText"
            :bodyText="dialog.bodyText"
            :params="dialog.params"
        />

    </v-container>
</template>

<script>
    import Helpers from '@/utilities/Helpers';
    import ActionDialog from "@/components/Layout/ActionDialog";
    import JobInformation from "@/components/Services/JobInformation";
    import { PROJECT_STATUS } from "@/utilities/Enums";

    export default ({

        components: {
            ActionDialog,
            JobInformation
        },

        mixins: [Helpers],

        data: () => ({

            loading: false,

			serviceProjectResponse: {
                id: 0,
                userNameRegister: "",
                customerSelected: {
                    name: ""
                },
                addressSelected: {
                    address: ""
                },
            },

            projectSelected: null,

            listPermittingRequest: [],
            
            listContractor: [],
            listTypeResponsibles: [],

            dialog: {
                show: false,
                headerText: "",
                bodyText: "",
                methodConfirm: ( () => {} ),
                params: null
            },

            PROJECT_STATUS
        }),

        props: {
            id: {
                default: 0
            }
        },

        methods: {

            async getLists() {
                this.listContractor = await this.$store.dispatch("contractorModule/List");
                this.listTypeResponsibles = await this.$store.dispatch("serviceModule/ListTypeOfResponsibles");
            },
            
            async getRegister() {

                if (this.id !== 0) {

                    let response = await this.$store.dispatch("serviceModule/GetServiceProjectById", this.id);

                    this.serviceProjectResponse = response.result;

                    this.projectSelected = {
                        id: this.serviceProjectResponse.id,
                        idService: this.serviceProjectResponse.idService,
                        classification: this.serviceProjectResponse.classification,
                        name: this.serviceProjectResponse.name,
                        step: this.serviceProjectResponse.step,
                        version: this.serviceProjectResponse.version,
                        versionName: this.serviceProjectResponse.versionName,
                        totalProject: this.serviceProjectResponse.totalProject
                    }
                }

                let responsePermitting = await this.$store.dispatch("serviceModule/GetPermittingById", this.id);
                
                this.listPermittingRequest = responsePermitting.result;
            },

            async cancel() {
                this.$router.push({ path: "/permitting/pipeline" });
            },
            
            checkDisable(item) {
                return item.apply == 0 || item.apply == null
            },

            async save(moveToNextStep) {

                this.showLoading();

                this.listPermittingRequest.forEach(itemPermitting => {
                    
                    if (itemPermitting.contractorSelected != null && itemPermitting.contractorSelected != undefined) {
                        itemPermitting.idContractor = itemPermitting.contractorSelected.id;
                    }

                    if (itemPermitting.responsibleSelected != null && itemPermitting.responsibleSelected != undefined) {
                        itemPermitting.responsible = '';

                        itemPermitting.responsibleSelected.forEach(item => {
                            itemPermitting.responsible += `${item.id};`
                        });
                    }
                });

                const result = await this.$store.dispatch("serviceModule/CreateUpdatePermitting", this.listPermittingRequest);

                if (result.success === true) {

                    this.showToast("success", "Success!", result.message);

                    if (moveToNextStep == true) {

                        let updateStatusRequest = {
                            id: this.id,
                            status: this.PROJECT_STATUS.PERMITTING_PERMIT_REVIEW
                        };

                        await this.$store.dispatch("serviceModule/UpdateProjectStatus", updateStatusRequest);
                    }

                    this.cancel();
                }
                else {
                    this.showToast("error", "Warning!", result.message);
                }

                this.hideLoading();
            },

            async moveToNextStep() {

                let messageValidationApply = '';
                let messageValidationResponsible = '';
                let messageValidationContractor = '';
                let isValid = true;

                this.listPermittingRequest.forEach(itemPermitting => {
                    if (itemPermitting.apply == null || itemPermitting.apply == undefined) {
                        messageValidationApply += `- ${itemPermitting.permitTitle} <br />`;
                        isValid = false
                    }

                    if (itemPermitting.apply == 1) {

                        if ((itemPermitting.responsible == null || itemPermitting.responsible == undefined)) {
                            messageValidationResponsible += `- ${itemPermitting.permitTitle}<br />`
                            isValid = false
                        }

                        if (itemPermitting.responsible == 3 && (itemPermitting.contractorSelected == null || itemPermitting.contractorSelected == undefined || itemPermitting.contractorSelected.id == 0)) {
                            messageValidationContractor += `- ${itemPermitting.permitTitle}<br />`
                            isValid = false
                        }
                    }
                });

                if (isValid == false) {
                    let messageValidation = 'Please check the permit items below: <br />';

                    if (messageValidationApply != '') {
                        messageValidation += `<br /> You must click "Not Applicable" or "Applicable" for the permits <br /> ${messageValidationApply}<br />`
                    }

                    if (messageValidationResponsible != '') {
                        messageValidation += `<br /> You must click "Van Kirk", "Homeowner" or "Contractor" for the permits <br /> ${messageValidationResponsible}`
                    }

                    if (messageValidationContractor != '') {
                        messageValidation += `<br /> You must select a contractor for the permits <br /> ${messageValidationContractor}`
                    }

                    this.showToast("error", "Warning!", messageValidation);
                }
                else {
                    
                    this.dialog = {
                        show: true,
                        headerText: 'Confirmation',
                        bodyText: `You will move this project to the next step "PERMIT IN REVIEW", confirm your decision?`,
                        methodConfirm: this.save,
                        params: true
                    };
                }
            }
        },

        async created() {

            this.showLoading();
            
            await this.getLists();
            await this.getRegister();

            this.hideLoading();
        }
    })
</script>